<template>
  <div class="home d-flex flex-column align-center fill-height">
    <!-- Volunteer -->
    <v-card id="vounteer" width="80%" class="mt-4 mb-10">
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        Volunteer
      </v-card-title>
      <v-divider />
      <v-card-text class="text-lg-subtitle-1 font-weight-bold"
        >If you'd be interested in volunteering to help with any of our groups,
        please contact Cole Maupin at (423) 444-1890, or by
        <a href="mailto:boys@biblestudytwo.org" class="contact">email</a>, and
        get a copy of our Volunteer Manual for information on the opportunities.
        We'd love to talk with you about them!
        <v-img
          style="margin-left: auto; margin-right: auto"
          src="@/assets/volunteer.webp"
          width="400px"
        ></v-img>
      </v-card-text>
    </v-card>
    <!-- Churches -->
    <v-card id="churches" width="80%" class="mt-4 mb-10">
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        Churches
      </v-card-title>
      <v-divider />
      <v-card-text class="text-lg-subtitle-1 font-weight-bold">
        We would love to start a relationship with church communities that are
        interested in assisting with BibleStudyTwo Ministries. Please contact
        <a href="mailto:treasurer@biblestudytwo.org" class="contact"
          >Mark Collins</a
        >
        for more information.
      </v-card-text>
    </v-card>
    <!-- Prayer -->
    <v-card id="prayer" width="80%" class="mt-4 mb-10">
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        Prayer
      </v-card-title>
      <v-divider />
      <v-card-text class="text-lg-subtitle-1 font-weight-bold">
        <p>
          Pray anytime, but especially as we go through our
          <a href="/#/about#schedule" class="contact">weekly routine</a>.
        </p>
        <p>
          If you would like to pray for someone with special needs please
          contact for the
          <a href="mailto:boys@biblestudytwo.org" class="contact">Boy's</a>
          or the
          <a href="mailto:girls@biblestudytwo.org" class="contact">Girl's</a>
          group.
        </p>
      </v-card-text>
    </v-card>
    <!-- Food -->
    <v-card id="food" width="80%" class="mt-4 mb-10">
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        Food
      </v-card-title>
      <v-divider />
      <v-card-text class="text-lg-subtitle-1 font-weight-bold">
        <p>
          It takes a lot of food to feed our groups every week. We have some
          standard items that regularly and would be especially grateful if you
          wanted to help out with our grocery list. Contact
          <a href="mailto:director@biblestudytwo.org" class="contact">Bob</a>
          and we'd be happy to pick them up from you.
        </p>
        <v-list>
          <!-- Mac & Cheese -->
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>
                Kraft Family-Size Macaroni and Cheese
              </v-list-item-title>
            </template>
            <v-list-item
              href="https://www.samsclub.com/p/mac-cheese-18-ct-18-pk-7-25-oz/prod18530167?itemNumber=885941&source=samsclub.com"
              target="_blank"
              class="blue--text ml-10"
            >
              Sam's Club
            </v-list-item>
            <v-divider />
            <v-list-item
              href="https://www.walmart.com/ip/Kraft-Original-Flavor-Macaroni-Cheese-Dinner-18-pk-7-25-oz/671015988"
              target="_blank"
              class="blue--text ml-10"
            >
              Walmart
            </v-list-item>
            <v-divider />
          </v-list-group>
          <!-- Gatorade -->
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title> Gatorade (12-oz Bottles) </v-list-item-title>
            </template>
            <v-list-item
              href="https://www.samsclub.com/p/gatorade-fp-ll-or-28-12-oz/prod11580745?itemNumber=258863&source=samsclub.com"
              target="_blank"
              class="blue--text ml-10"
            >
              Sam's Club
            </v-list-item>
            <v-divider />
            <v-list-item
              href="https://www.walmart.com/ip/Gatorade-Thirst-Quencher-Variety-Pack-12-fl-oz-18-count/16224470"
              target="_blank"
              class="blue--text ml-10"
            >
              Walmart
            </v-list-item>
            <v-divider />
            <v-list-item
              href="https://www.foodcity.com/product/0005200012324/"
              target="_blank"
              class="blue--text ml-10"
            >
              Food City
            </v-list-item>
            <v-divider />
          </v-list-group>
          <!-- Soda -->
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>
                Coke, Mountain Dew, Sprite, Dr. Pepper and Yoo-Hoo (12-oz Cans)
              </v-list-item-title>
            </template>
            <!-- Sam's -->
            <v-list-group sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Sam's Club</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                href="https://www.samsclub.com/s/coca-cola%2012%20oz"
                target="_blank"
                class="blue--text ml-10"
              >
                Coke
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.samsclub.com/s/mountain%20dew%2012%20oz"
                target="_blank"
                class="blue--text ml-10"
              >
                Mountain Dew
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.samsclub.com/s/sprite%2012%20oz"
                target="_blank"
                class="blue--text ml-10"
              >
                Sprite
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.samsclub.com/s/dr%20pepper%2012%20oz"
                target="_blank"
                class="blue--text ml-10"
              >
                Dr Pepper
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.samsclub.com/s/yoo-hoo%2011%20oz"
                target="_blank"
                class="blue--text ml-10"
              >
                Yoo-Hoo
              </v-list-item>
              <v-divider />
            </v-list-group>
            <!-- Walmart -->
            <v-list-group sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Walmart</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                href="https://www.walmart.com/ip/Coca-Cola-Soda-Soft-Drink-12-fl-oz-12-Pack/12166733"
                target="_blank"
                class="blue--text ml-10"
              >
                Coke 12pk
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.walmart.com/ip/Coca-Cola-Soda-12-Fl-Oz-24-Count/10535216"
                target="_blank"
                class="blue--text ml-10"
              >
                Coke 24pk
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.walmart.com/ip/Mountain-Dew-12-oz-Cans-24-Count/16821023"
                target="_blank"
                class="blue--text ml-10"
              >
                Mountain Dew 24pk
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.walmart.com/ip/Sprite-Lemon-Lime-Soda-12-Fl-Oz-12-Count/10291611"
                target="_blank"
                class="blue--text ml-10"
              >
                Sprite 12pk
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.walmart.com/ip/2-Pack-Dr-Pepper-12-Fl-Oz-Cans-12-Ct/266055339"
                target="_blank"
                class="blue--text ml-10"
              >
                Dr Pepper 12pk
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.walmart.com/ip/Dr-Pepper-Soda-12-Fl-Oz-24-Count/15754316"
                target="_blank"
                class="blue--text ml-10"
              >
                Dr Pepper 24pk
              </v-list-item>
              <v-divider />
              <v-list-item
                href="https://www.walmart.com/ip/Yoo-hoo-Chocolate-Drink-11-fl-oz-cans-12-pack/38125809"
                target="_blank"
                class="blue--text ml-10"
              >
                Yoo-Hoo 12pk
              </v-list-item>
              <v-divider />
            </v-list-group>
          </v-list-group>
          <!-- Water -->
          <v-list-group>
            <template v-slot:activator>
              <v-list-item-title>
                Bottled Water (0.5 Liter / 16.9 Oz Bottles)
              </v-list-item-title>
            </template>
            <v-list-item
              href="https://www.samsclub.com/p/mm-water-45-16-9-oz-45-16-9-oz/prod21063721?itemNumber=980002151&source=samsclub.com"
              target="_blank"
              class="blue--text ml-10"
            >
              Sam's Club
            </v-list-item>
            <v-divider />
            <v-list-item
              href="https://www.walmart.com/ip/Great-Value-Purified-Drinking-Water-16-9-Fl-Oz-40-Count/992524020?athbdg=L1600"
              target="_blank"
              class="blue--text ml-10"
            >
              Walmart
            </v-list-item>
            <v-divider />
            <v-list-item
              href="https://www.foodcity.com/product/0003680003973/"
              target="_blank"
              class="blue--text ml-10"
            >
              Food City
            </v-list-item>
            <v-divider />
          </v-list-group>
          <!-- Ground Beef -->
          <v-list-item>
            <v-list-item-title> Ground Beef (90/10 or 93/7) </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              Boneless Chicken Breasts or Tenders
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <p>
          If you'd like to provide fruit or other foods for the Girl's Group
          please contact
          <a href="mailto:girls@biblestudytwo.org" class="contact">us</a>.
        </p>
        <p>
          If you'd like to make an entree, side dish or dessert for the Boy's
          Group please contact
          <a href="mailto:director@biblestudytwo.org" class="contact">us</a>.
        </p>
      </v-card-text>
    </v-card>
    <!-- PARTNERS -->
    <v-card id="partners" width="80%" class="mt-4 mb-10">
      <v-divider class="mt-5" />
      <v-card-title
        class="
          d-flex
          justify-center
          font-weight-bold
          text-lg-h2
          blue-grey--text
        "
      >
        PARTNERS
      </v-card-title>
      <v-divider class="mb-5" />
      <v-card-text
        class="
          d-flex
          justify-md-space-around
          flex-column flex-md-row
          align-center
          flex-wrap
        "
        style="width: 100%"
      >
        <div
          :class="$vuetify.breakpoint.mdAndDown ? 'fullWidth' : 'threeColumn'"
        >
          <a href="https://www.greatbodycompany.com/" target="_blank">
            <v-img src="@/assets/gbc_logo.png" width="100%" />
          </a>
        </div>
        <div
          class="d-flex align-center"
          :class="
            $vuetify.breakpoint.mdAndDown ? 'fullWidth mt-10' : 'threeColumn'
          "
        >
          <a
            href="https://www.christbiblechurchkingsport.com/"
            target="_blank"
            style="width: 100%"
          >
            <v-img src="@/assets/cbc_logo.webp" />
          </a>
        </div>
        <div
          style="height: 20rem"
          class="d-flex align-center justify-center"
          :class="$vuetify.breakpoint.mdAndDown ? 'fullWidth' : 'threeColumn'"
        >
          <a
            href="https://calvaryofkpt.faithlifesites.com/"
            target="_blank"
            style="width: 100%; height: 100%"
          >
            <v-img src="@/assets/calvary_logo.webp" contain height="100%" />
          </a>
        </div>
        <div
          class="d-flex align-center justify-center ma-5"
          :class="$vuetify.breakpoint.mdAndDown ? 'fullWidth' : 'threeColumn'"
        >
          <v-img src="@/assets/odbc_logo.png" contain height="100%" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GetInvovled",
};
</script>

<style scoped>
a.contact {
  color: rgba(0, 0, 0, 0.6);
}
.threeColumn {
  max-width: 30%;
}
.fullWidth {
  max-width: 80%;
}
</style>
